<template>
  <div>
    <!-- <h3
    class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2 text-red-900"
    v-if="state == 'TN'"
  >
    National Exam Dates
  </h3> -->

    <hr
      class="hidden lg:block mt-5"
      :class="{
        ...formCssClasses('hr'),
      }"
    />

    <!-- Payment Fields -->

    <mobile-information-card class="lg:hidden"></mobile-information-card>

    <validation-observer v-slot="{ handleSubmit }">
      <!-- <national-exam-registration-select v-if="state == 'TN'">
    </national-exam-registration-select> -->

      <div v-if="state == 'TN'">
        <!-- <div v-if="availableForPayment && courseCost >  0"> -->
        <deposit-tn-full-select></deposit-tn-full-select>
        <mobile-price-table class="mt-6 lg:hidden"></mobile-price-table>
        <billing-fields v-if="remainingBalance > 0"></billing-fields>
        <billing-address-fields
          v-if="remainingBalance > 0"
        ></billing-address-fields>

        <!-- </div> -->
      </div>
      <div v-else>
        <deposit-full-select></deposit-full-select>
        <mobile-price-table class="mt-6 lg:hidden"></mobile-price-table>
        <billing-fields v-if="remainingBalance > 0"></billing-fields>
        <!-- <billing-fields></billing-fields> -->

        <billing-address-fields
          v-if="remainingBalance > 0"
        ></billing-address-fields>
        <!-- <billing-address-fields></billing-address-fields> -->
      </div>
      <!-- End Payment Fields -->

      <!-- <hr
      class="mt-8 mb-4"
      :class="{
        ...formCssClasses('hr'),
      }"
    > -->

      <!-- Terms of Service -->

      <div v-if="state == 'TN' && remainingBalance > 0">
        <label class="inline-flex">
          <input
            type="checkbox"
            v-model="applicationFee"
            :class="{ ...formCssClasses('radio_button') }"
            class="form-checkbox h-5 w-5"
          />
          <span
            class="ml-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            I understand application fee is non refundable
          </span>
        </label>
      </div>

      <!-- End Terms of Service -->

      <!-- Terms of Service -->
      <!-- <div class="mt-6">
      <label class="inline-flex">
        <input
          type="checkbox"
          v-model="termsOfService"
          :class="{
            ...formCssClasses('radio_button'),
          }"
          class="form-checkbox h-5 w-5"
        >
          <span
            class="ml-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            I have read the
            <a class="hover:text-blue-700 text-blue-500 underline" target="_blank" :href="termsOfServiceLink">
              enrollment agreement and privacy policy
            </a>
            and agree to the terms listed therein.
          </span>
      </label>
    </div>
    <div v-if="state == 'TN'">
      <label class="inline-flex">
        <input
          type="checkbox"
          v-model="applicationFee"
          :class="{
            ...formCssClasses('radio_button'),
          }"
          class="form-checkbox h-5 w-5"
        >
          <span
            class="ml-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            I understand application fee is non refundable
          </span>
      </label>
    </div>
    <div class="mt-4" v-if="hasSecondQuestion">
      <label class="inline-flex items-center">
        <input type="checkbox"
          v-model="secondQuestion"
          :class="{
            ...formCssClasses('radio_button'),
          }"
          class="form-checkbox h-5 w-5"
        >
          <span
            v-if="state == 'CA'"
            class="ml-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            I am proficient in reading and writing the English Language.
          </span>
          <span
            v-else-if="state == 'OH'"
            class="ml-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            I Understand that the State of Ohio requires that I watch a 5 minute Student Consumer video before I attend class. This link along with instructions will be emailed upon registration
          </span>
      </label>
    </div> -->
      <!-- End Terms of Service -->

      <next-back-buttons
        :next-blocked="!ready"
        :loading="loading"
        :next-text="buttonText"
        v-on:next="handleSubmit(onSubmit)"
        v-on:back="back"
      >
      </next-back-buttons>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { ValidationObserver } from "vee-validate";

import MobilePriceTable from "@/components/Checkout/SideBar/Elements/MobilePriceTable.vue";
import DepositFullSelect from "./Elements/DepositFullSelect.vue";
import DepositTNFullSelect from "./Elements/DepositTNFullSelect.vue";
import BillingFields from "./Elements/BillingFields.vue";
import BillingAddressFields from "./Elements/BillingAddressFields.vue";
import NextBackButtons from "./Elements/NextBackButtons.vue";
import NationalExamRegistrationSelect from "./Elements/NationalExamRegistrationSelect.vue";
import MobileInformationCard from "@/components/Checkout/SideBar/Elements/MobileInformationCard.vue";
//import CourseCoupon from '@/components/Checkout/SideBar/Elements/CourseCoupon.vue'

export default {
  props: ["loading"],
  components: {
    "validation-observer": ValidationObserver,
    "mobile-price-table": MobilePriceTable,
    "deposit-full-select": DepositFullSelect,
    "deposit-tn-full-select": DepositTNFullSelect,
    "billing-fields": BillingFields,
    "billing-address-fields": BillingAddressFields,
    "next-back-buttons": NextBackButtons,
    "national-exam-registration-select": NationalExamRegistrationSelect,
    "mobile-information-card": MobileInformationCard,
  },
  computed: {
    ...mapState("courseInformation", [
      "state",
      "termsOfServiceLink",
      "examDates",
      "availableForPayment",
      "courseCost",
      "state_fee_total",
      "courseTypeId",
      "tnApplicationFee",
    ]),
    ...mapState("formData", ["paySelected"]),
    ...mapGetters("courseInformation", [
      "hasSecondQuestion",
      "totalAmount",
      "discountCodeAmount",
      "remainingBalance",
    ]),
    ...mapGetters(["formCssClasses"]),
    ready() {
      if (this.state === "TN") {
        return this.applicationFee && !this.loading;
      }
      return !this.loading;
    },
    totalPaymentToday() {
      if (this.paySelected === "payFull") {
        return this.discountCodeAmount >= this.totalAmount
          ? 0
          : this.totalAmount + this.tnApplicationFee - this.discountCodeAmount;
      } else if (
        this.paySelected === "payNotFull" ||
        this.paySelected === "payCustom"
      ) {
        return this.totalAmount + this.tnApplicationFee;
      } else {
        return this.discountCodeAmount >= this.totalAmount
          ? 0
          : this.tnApplicationFee;
      }
    },

    buttonText() {
      // if (this.state === 'TN' && !this.availableForPayment) {
      //   //return 'Sign Up Now';
      //   return 'Pay $'  + this.totalPaymentToday
      // }
      //if (this.state == "TN" && this.remainingBalance === 0) return "Continue";

      if (this.totalPaymentToday === 0) return "Continue";

      return "Pay $" + this.totalPaymentToday;
    },
    getAgreeCheckBoxColor() {
      return this.courseTypeId == 4
        ? "focus:outline-blue-100 text-blue-700"
        : "focus:outline-red-100 text-red-700";
    },
    getAgreeSpanColor() {
      return this.courseTypeId == 4 ? "text-blue-900" : "text-red-900";
    },
  },
  watch: {
    discountCodeAmount(value) {
      if ((this.state === "TN") & (value >= this.state_fee_total))
        this.applicationFee = true;
    },
  },
  methods: {
    onSubmit: function() {
      if (this.ready) {
        this.$emit("next");
      }
    },
    back: function() {
      this.$emit("back");
    },
  },
  data: function() {
    return {
      termsOfService: false,
      applicationFee: false,
      secondQuestion: false,
    };
  },
};
</script>
