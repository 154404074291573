import axios from "axios";
import router from "@/router/index";

//const type = urlParams.get('type')
//console.log(type);
// initial state
const state = {
  valid: "loading",
  stateFeeFound: false,
  id: null,
  name: null,
  state: null,
  stateName: "",
  courseState: {
    name: null,
    ssnType: null,
  },
  city: null,
  isDepositAmountDirty: false,
  termsOfServiceLink: null,
  courseCost: null,
  examFeeCost: null,
  withExamFee: true,
  insuranceCost: null,
  externshipCost: 0,
  depositAmount: null,
  formattedName: null,
  courseTime: null,
  courseType: null,
  state_fee_items: [],
  state_fee_total: 0,
  required_checkout_total: 0,
  //courseTypeId: type ? type : 0, // 3 - EKG, 4 - IV
  courseTypeId: 0, // 3 - EKG, 4 - IV
  courseStartDate: null,
  landing_page: null,
  // Success Page
  formattedTimes: null,
  // TN Specific
  availableForPayment: true,
  applyForApplicants: false,
  tnApplicationFee: 0,
  examDates: [],
  isAccountCreated: false,
  studentToken: "",
  //
  cprCourse: null,
  cprCourses: [],
  statesGradDocNotRequired: [],
  gradDocRequired: null,
  // tracking fields
  stateTrackingFields: [],
  referralOpts: [],
  discountCode: {
    id: null,
    name: null,
    amount: 0,
    isValid: false,
    error: "",
    success: "",
  },
  //isHybridCourse: false,
};

// mutations
const mutations = {
  // setCourseCosts: (state, payload) => {
  //   state.courseCost = payload;
  // },
  setCourseFields: (state, payload) => {
    state[payload.field] = payload.value;
  },
  setIsAccountCreated: (state, payload) => {
    state.isAccountCreated = payload;
  },
  setStudentToken: (state, payload) => {
    state.studentToken = payload;
  },
  setWithExamFee: (state, payload) => {
    state.withExamFee = payload;
  },
  setStatesGradDocNotRequired: (state, payload) => {
    state.statesGradDocNotRequired = payload;
  },
  setGradDocRequired: (state, payload) => {
    state.gradDocRequired = payload;
  },
  setCprCourses: (state, payload) => {
    state.cprCourses = payload;
  },
  setCprCourse: (state, payload) => {
    state.cprCourse = payload;
  },
  // setStateName: (state, payload) => {
  //   state.stateName =  payload;
  // },
  setCourseState: (state, payload) => {
    for (const key in payload) {
      state.courseState[key] = payload[key];
    }
  },
  setStateTrackingFields: (state, payload) => {
    state.stateTrackingFields = payload;
  },
  setReferralOpts: (state, payload) => {
    state.referralOpts = payload;
  },
  setDiscountCode: (state, payload) => {
    for (const key in payload) {
      state.discountCode[key] = payload[key];
    }
  },
  updateDepositAmount: (state, payload) => {
    if (state.isDepositAmountDirty) return;
    state.depositAmount = state.depositAmount - payload;
    state.isDepositAmountDirty = true;
  },
  // setIsHybridCourse: (state, payload) => {
  //   state.isHybridCourse = payload;
  // },
  setApplyForApplicants: (state, payload) => {
    state.applyForApplicants = payload;
  },
};

// getters
const getters = {
  courseCost: (state) => {
    //return state.discountCode.isValid ? state.courseCost - (state.discountCode.amount / 100) : state.courseCost
    return state.courseCost;
  },
  discountCodeAmount: (state) => {
    return state.discountCode.isValid ? state.discountCode.amount : 0;
  },
  isAvailableDiscountCoupon: (state) => {
    return state.courseTypeId === 1; /*&& (state.availableForPayment)*/
  },
  totalPrice: (state, getters, rootState) => {
    return state.state_fee_total;
    if (state.state == "CA")
      return (
        getters.courseCost +
        state.examFeeCost +
        state.insuranceCost +
        state.externshipCost
      );
    else if (state.state == "TN") {
      if (state.withExamFee)
        return getters.courseCost + state.examFeeCost + state.insuranceCost;
      return getters.courseCost + state.insuranceCost;
    }
    return getters.courseCost + state.examFeeCost + state.insuranceCost;
    // if (state.state == 'CA') {
    //   //return state.courseCost + state.examFeeCost + state.insuranceCost + state.externshipCost;
    //   return getters.courseCost + state.examFeeCost + state.insuranceCost + state.externshipCost;
    // }else if (state.state == 'TN') {
    //   return getters.courseCost + state.insuranceCost;
    //   //return state.courseCost + state.insuranceCost;
    //   //return state.courseCost + state.examFeeCost + state.insuranceCost;
    // }else {
    //   //return state.courseCost + state.examFeeCost + state.insuranceCost;
    //   return getters.courseCost + state.examFeeCost + state.insuranceCost;
    //   // return state.courseCost + state.examFeeCost + state.insuranceCost +  (rootState.formData.loadDataExam ? 100 : 0);
    // }
  },
  totalAmount: (state, getters, rootState) => {
    const { payCustomAmount, paySelected } = rootState.formData;

    if (paySelected === "payCustom")
      return payCustomAmount === "" ? 0 : payCustomAmount;
    else if (paySelected === "payNotFull") return state.depositAmount;
    // deposit only
    else if (paySelected === "tnApplicacionFee") return state.tnApplicationFee;
    return getters.totalPrice; // pay in full
  },
  remainingBalance: (state, getters, rootState) => {
    // if(paySelected === 'payCustom') return getters.totalPrice  - (payCustomAmount === '' ? 0 : payCustomAmount);
    // else if(paySelected === 'payNotFull') return getters.totalPrice - state.depositAmount; // only deposit
    //else if(paySelected === 'tnApplicacionFee') return getters.totalPrice;
    // const remainingBalance = state.discountCode.isValid ?
    //   (
    //     getters.discountCodeAmount > getters.totalAmount ?
    //     (getters.totalPrice) - (getters.discountCodeAmount) :
    //     (getters.totalAmount) - (getters.discountCodeAmount)
    //   ) :
    //   (getters.totalPrice) - (getters.totalAmount);
    //const remainingBalance = getters.totalAmount - getters.discountCodeAmount;
    const { paySelected } = rootState.formData;
    if (paySelected === "payFull")
      return getters.totalAmount - getters.discountCodeAmount;
    return (
      getters.totalPrice - getters.totalAmount - getters.discountCodeAmount
    );

    // if(payCustom){
    //     return ( getters.totalPrice - getters.discountCodeAmount ) - (payCustomAmount == '' ? 0 : payCustomAmount);
    // }
    // else if(paySelected === 'tnApplicacionFee'){
    //   return getters.totalPrice - getters.discountCodeAmount;
    // }
    // return (getters.totalPrice - getters.discountCodeAmount) - state.depositAmount;
  },
  hasIntroQuestion: (state) => {
    return state.state == "CA" || state.state == "TN";
  },
  // hasSecondQuestion: (state) => {
  // 	return state.state == 'CA' ;
  // },
};
// actions
const actions = {
  loadData({ commit }, { courseId, courseType }) {
    //console.log(courseType);
    if (courseId) {
      axios
        .get(process.env.VUE_APP_API_URL + "api/course/" + courseId)
        .then(({ data }) => {
          //console.log(data);
          if (data.success == true) {
            // Set all the information to
            const course = data.course;

            if (course.availability === "full") {
              state.valid = course.availability;
              // go to the top of the screen so the user can see the error
              if (course.type.course_type == "National") {
                state.landing_page = course.city.landing_page;
              } else if (course.type.course_type == "ECG") {
                state.landing_page = course.city.ecg_landing_page;
              } else if (course.type.course_type == "IV") {
                state.landing_page = "https://ivtrainingcenter.com/";
              }

              if (!state.landing_page)
                state.landing_page =
                  "https://www.phlebotomyusa.com/phlebotomy-school-locations/";

              window.scroll(0, 0);
              return;
            }

            //console.log(course);
            state.id = courseId;
            state.valid = "finished";
            //state.name = course.formatted_name;
            document.title = course.formatted_name;
            // console.log(document.title);
            // console.log(course.formatted_name);
            commit("setCourseFields", {
              field: "name",
              value: course.formatted_name,
            });
            state.state = course.city.state.abbreviation;
            commit("setCourseFields", {
              field: "city",
              value: course.city.permalink,
            });
            state.termsOfServiceLink = course.enrollment_agreement;
            state.courseCost = course.cost;
            state.examFeeCost = course.exam_cost;
            state.insuranceCost = course.insurance_cost;
            // state.depositAmount = course.deposit;
            state.courseStartDate = course.start_date;
            if (course.type.course_type == "National") {
              state.landing_page = course.city.landing_page;
            } else if (course.type.course_type == "ECG") {
              state.landing_page = course.city.ecg_landing_page;
            } else if (course.type.course_type == "IV") {
              state.landing_page = "https://ivtrainingcenter.com/";
            }

            // if (!state.landing_page)
            //   state.landing_page =
            //     "https://www.phlebotomyusa.com/phlebotomy-school-locations/";

            state.formattedTimes = course.formatted_times;
            state.formattedName = course.formatted_name;
            state.courseTime = course.time.course_time;
            //console.log(course);
            state.state_fee_items = data.state_fee_items;
            state.state_fee_total = +data.state_fee_total;
            state.depositAmount = +data.required_checkout_total;
            state.stateFeeFound = +data.state_fee_found;
            // state.courseType = course.type.course_type;
            commit("setCourseFields", {
              field: "courseType",
              value: course.type.course_type,
            });
            commit(
              "setStatesGradDocNotRequired",
              data.states_grad_doc_not_required
            );
            commit("setGradDocRequired", data.grad_doc_required);
            commit("setCprCourse", course.cpr_course);
            commit("setCprCourses", data.cpr_courses || []);
            //commit('setStateName', course.city.state.name || '');
            const courseState = {
              name: course.city.state.name || null,
              ssnType: course.city.state.ssn_type || null,
            };

            commit("setCourseState", courseState);

            commit("setStateTrackingFields", data.tracking_fields || []);
            if (data.tracking_fields.length) {
              const studentTrackingFields = data.tracking_fields.map(
                (field) => ({
                  id: field.id,
                  //name: field.column_name,
                  value_type: field.value_type,
                  value: field.value_type === "[multiple_answer]" ? [] : "",
                })
              );
              commit(
                "formData/setTrackingFields",
                studentTrackingFields || [],
                { root: true }
              );
            }
            commit("setReferralOpts", data.referral_opts);
            const { currentRoute } = router;
            const { type } = currentRoute.query;
            //commit('setCourseFields', { field: 'courseTypeId', value: 4 });
            //if(course.type.id === 3) {

            commit("setCourseFields", {
              field: "courseTypeId",
              value: type ? parseInt(type) : course.type.id,
            });

            //commit("setIsHybridCourse", data.is_hybrid_course);
            commit("setApplyForApplicants", course.apply_for_applicants);
            //}

            if (state.state == "TN") {
              state.availableForPayment = course.available_for_payment;
              state.tnApplicationFee = course.tn_application_fee;
              commit("checkoutData/updateNumForm", 6, { root: true });
              //dispatch('loadExamDates', courseId);
            } else if (state.state == "CA") {
              state.externshipCost = course.externship_cost;
              commit("checkoutData/updateNumForm", 3, { root: true });
            } else if (state.state == "NV") {
              commit("checkoutData/updateNumForm", 3, { root: true });
            } else if (state.state == "KY") {
              //commit('checkoutData/updateNumForm', 4, {root:true} );
              commit("checkoutData/updateNumForm", 5, { root: true });
            }
            // else if(state.state == 'TX'){
            //   commit('checkoutData/updateNumForm', 5, {root:true} );
            // }
            else {
              // if there is anything else we need one less step for the form
              commit("checkoutData/updateNumForm", 3, { root: true });
            }

            if (state.courseType == "IV") {
              commit("checkoutData/updateNumForm", 4, { root: true });
            }

            // ***************** Comented out for testing ***************
            // If is anything other than CA or TN skip the first step
            // let currentForm = (state.state == 'CA' || state.state == 'TN') ? 1 : 2;
            // commit('checkoutData/updateCurrentForm', currentForm, {root:true} );
          } else {
            // The course does not exist
            state.valid = false;
          }
        })
        .catch((error) => {
          state.valid = false;
          // Generice error telling to call and try again later
          commit("errorMessage/updateStatus", true, { root: true });
          commit("errorMessage/updateType", "Not Found", { root: true });
          commit(
            "errorMessage/updateMessage",
            `There was an error, please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} or try again another course.`,
            { root: true }
          );

          // go to the top of the screen so the user can see the error
          window.scroll(0, 0);
          console.log(error?.response?.data?.message || error);
        });
    } else {
      state.valid = false;
    }
  },
  loadExamDates(context, courseId) {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "api/course/" +
          courseId +
          "/national_exams"
      )
      .then((response) => {
        let nationalExams = response.data.national_exams.map(function(object) {
          return {
            id: object.id,
            date: object.start_date,
            dayOfTheWeek: object.formatted_days,
            formattedDate:
              object.formatted_dates + " " + object.start_date.slice(0, 4),
            time: object.formatted_times,
          };
        });
        if (nationalExams.length > 0) {
          context.commit("formData/updateNationalExamId", nationalExams[0].id, {
            root: true,
          });
        } else {
          context.commit("setWithExamFee", false);
        }

        state.examDates = nationalExams;
      })
      .catch((error) => {
        state.valid = false;
        // Generice error telling to call and try again later
        context.commit("errorMessage/updateStatus", true, { root: true });
        context.commit("errorMessage/updateType", "Exam List Not Found", {
          root: true,
        });
        context.commit(
          "errorMessage/updateMessage",
          `There was an error, please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} or try again another course.`,
          { root: true }
        );

        // go to the top of the screen so the user can see the error
        window.scroll(0, 0);
        console.log(error);
      });
  },
  createStudentAccount: async ({ state, commit, rootState }, { password }) => {
    try {
      //console.log(password);
      //console.log(state.studentToken);
      const post = {
        password,
        token: state.studentToken,
        course_type_id: state.courseTypeId,
        state: state.state,
        enrollment_id: rootState.responseData.enrollmentId,
        //is_hybrid_course: state.isHybridCourse,
      };
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}api/checkout/create_account`,
        post
      );
      //console.log(data);
      if (!data.success) {
        commit("errorMessage/updateStatus", true, { root: true });
        commit("errorMessage/updateType", "Server", { root: true });
        commit("errorMessage/updateMessage", data.msg, { root: true });
      } else {
        commit("setIsAccountCreated", true);
      }
    } catch (error) {
      console.log(error);
      commit("errorMessage/updateStatus", true, { root: true });
      commit("errorMessage/updateType", "Server", { root: true });
      commit("errorMessage/updateMessage", "Please try again.", { root: true });
      window.scroll(0, 0);
    }
  },
  verifyDiscountCode: async ({ state, commit, rootState }) => {
    try {
      commit("checkoutData/updateLoading", true, { root: true });
      commit("setDiscountCode", { error: "", success: "" });
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}api/coupon/verify?course_id=${state.id}&discount_code=${state.discountCode.code}&email=${rootState.formData.email}`
      );

      if (data.valid) {
        commit("setDiscountCode", {
          id: data.discount_code_id,
          name: state.discountCode.code,
          amount: data.discount_amount,
          isValid: true,
          error: "",
          success: "Discount Code Applied",
        });
        if (data.course_discount_type == "deposit_only") {
          commit("updateDepositAmount", data.discount_amount);
        }
        commit("formData/updateShowMobileCheckoutBreakdown", true, {
          root: true,
        });
        // commit('formData/setPayCustom', false, { root: true });
        // commit('formData/setPayCustomAmount', '', { root: true });
        // commit('formData/setPaySelected', 'payFull', { root: true });
        // commit('formData/updatePayFull', true, { root: true });

        //commit('setCourseCosts', (data.course_cost / 100));
        //state.courseCost = data.course_cost;
      } else {
        commit("setDiscountCode", {
          id: null,
          name: null,
          amount: 0,
          isValid: false,
          error: data.message,
          success: "",
        });
      }
      //console.log(data);
      commit("checkoutData/updateLoading", false, { root: true });
    } catch (error) {
      commit("checkoutData/updateLoading", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
