<template>
  <div class="text-gray-700 mt-5">
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="w-5/6"></th>
          <th class="w-1/6"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="border-b px-4 py-2 text-center" colspan="2">
            <label class="font-bold">
              Discount Code
            </label>
          </td>
        </tr>
        <tr
          class="border-2"
          :class="{ 'border-2 border-red-500' : errors.code }"
        >
          <td class="border-b">
            <input
              type="text"
              :value="discountCode.code"
              @input="onInput('code', $event)"
              @keypress="onKeyPress('code', $event)"
              class="px-2 py-4 placeholder-red-300 text-red-900 font-semibold bg-white rounded text-base shadow outline-none w-full"
              :disabled="discountCode.isValid"
            />

          </td>
          <td class="border-b h-full">
            <button
              type="button"
              class="w-full py-4 focus:outline-none text-white font-bold"
              :class="getNextButtonColor"
              :disabled="loading"
              @click="onVerifyCode"
            >
            <moon-loader class="px-4" :loading="loading" color="#FFF5F5" size="25px"></moon-loader>
            <span v-if="!loading">Apply</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ discountCode.error }}</p>
    <p class="mt-1 ml-1 text-green-500 text-sm font-semibold italic">{{ discountCode.success }}</p>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { MoonLoader } from 'vue-spinner/dist/vue-spinner.min.js';

  export default {
    components: {
      MoonLoader,
    },
    data: () => ({
      code: '',
      errors: {
        code: ''
      }
    }),
    created() {
      //this.code = this.discountCode.code || '';
    },
    computed: {
      ...mapState('courseInformation', ['courseTypeId', 'discountCode']),
      ...mapState('formData', ['paySelected']),
      ...mapState('checkoutData', ['loading']),
      getNextButtonColor(){
      return this.courseTypeId == 4 ?
        'bg-blue-800 hover:bg-blue-900'
        : ( this.courseTypeId === 11 ? 'bg-green-ecg hover:bg-green-900' : 'bg-red-800 hover:bg-red-900' );
    },
    },
    methods: {
      ...mapMutations('courseInformation', ['setDiscountCode']),
      ...mapActions('courseInformation', ['verifyDiscountCode']),
      onInput(field, e) {
        //console.log(e);
        this.setDiscountCode({
          code: e.target.value,
          //error: '',
          //success: '',
        })
        //this.errors[field] = '';
        //this[field] = e.target.value;
      },
      onKeyPress(field, e) {
        const onlyNumbers = /^[0-9]+$/;
        const onlyLettersAndNumbers = /[a-zA-Z0-9_]/;

        if(!onlyNumbers.test(e.key) && !onlyLettersAndNumbers.test(e.key)){
            e.preventDefault();
        }
      },
      onVerifyCode() {
        // this.errors.code = '';
        // this.verifyDiscountCode({ discountCode: this.code }).then( data => {
        //   //console.log(data);
        //   if(!data.valid) {
        //     this.errors.code = data.message;
        //   }
        // })
        this.verifyDiscountCode();
      }
    }
  }
</script>

<style>

</style>