<template>
<div>
  <h3
    class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2"
    :class="{
      ...formCssClasses('label'),
    }"
  >
    Student Registration Information
  </h3>

  <hr
    class="mt-5"
    :class="{
      ...formCssClasses('hr')
    }"
  >

  <!-- <validation-observer ref="formBasic" v-slot="{ handleSubmit}"> -->

      <!-- Email -->
      <div class="md:flex mt-8 mb-2">

        <div class="md:flex-1 md:mr-4  pt-0">

           <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            Email
          </label>

          <!-- <validation-provider name="email" rules="required|custom_email" v-slot="{ errors }"> -->

            <!-- Mobile Version -->
            <input
              type="text"
              :value="email"
              @input="onInput('email', 'email', $event)"
              @blur="onBlurEmail"
              :class="{
                'border-2 border-red-500' : errors.email,
                'border-solid border-4 border-light-blue-500' : isTranscriptReceived || isApproved,
                ...formCssClasses('input')
              }"
              placeholder="Email"
              class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
              :disabled="isTranscriptReceived || isApproved"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input
              type="text"
              :value="email"
              @input="onInput('email', 'email', $event)"
              @blur="onBlurEmail"
              :class="{
                'border-2 border-red-500' : errors.email,
                'border-solid border-4 border-light-blue-500' : isTranscriptReceived || isApproved,
                ...formCssClasses('input'),
              }"
              placeholder="example@gmail.com"
              class="hidden sm:block px-3 py-4 relative bg-white  rounded text-base shadow-md outline-none focus:outline-red-200 w-full"
              :disabled="isTranscriptReceived || isApproved"
            />
            <!-- End Desktop Version -->
            <p
              class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
              v-if="errors.email"
              >
              <span v-if="errors.email == 'exists'">
                It looks like you already have an account with us.
                Please call {{supportPhoneNumber}} to register to a new class.
              </span>
              <span v-else-if="errors.email == 'can_register'">
                YOU ALREADY HAVE AN ACCOUNT! <br>
                Please a <a class="underline" :href="`${studentPortalUrl}/student/checkout?c=${id}`">click here</a> to access your student portal to register for the class
              </span>
              <span v-else>
                {{ errors.email }}
              </span>
            </p>
            <p
              style="color: #EE930E;"
              class="mt-1 ml-1 text-red-500 text-sm font-semibold italic"
              v-else-if="notices.email"
              >
              <span v-if="notices.email == 'suggestion'">
                Did you mean: {{suggestion}}.
              </span>
              <span v-else-if="notices.email == 'Invalid'">
                Please verify your email address
              </span>
              <span v-else-if="notices.email == 'Risky'">
                If you don’t receive an email following registration, please check your junk and spam folders.
              </span>
            </p>
            <!-- <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic" v-else>{{ errors.email }}</p> -->



        <!--</validation-provider> -->
        </div>
        <div class="md:flex-1">
          <p class="text-gray-500 text-xs p-2  md:mt-5">
            By entering your email you agree to receive email correspondence from PTS.
          </p>
        </div>

      </div>
      <!-- End email -->

      <!-- Name and Lastname -->
      <div class="md:flex md:mt-3 sm:mt-6">

        <div class="md:flex-1 mb-4 md:mr-4 pt-0">

           <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            Name
          </label>
            <!-- Mobile Version -->
            <input
              type="text"
              :class="{
                'border-2 border-red-500' : errors.name,
                ...formCssClasses('input'),
              }"
              :value="name"
              @input="onInput('name', 'name', $event)"
              @blur="onBlurFirstName"
              placeholder="Name"
              class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input
              type="text"
              :class="{
                'border-2 border-red-500' : errors.name,
                ...formCssClasses('input'),
              }"
              :value="name"
              @input="onInput('name', 'name', $event)"
              @blur="onBlurFirstName"
              placeholder="John"
              class="hidden sm:block px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.name }}</p>
          <!-- </validation-provider> -->

        </div>

        <div class="md:flex-1 mb-4 pt-0">

           <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            Last Name
          </label>

          <!-- <validation-provider name="last name" rules="required" v-slot="{ errors }"> -->

            <!-- Mobile Version -->
            <input
              type="text"
              :class="{
                'border-2 border-red-500' : errors.lastName,
                ...formCssClasses('input'),
              }"
              :value="lastName"
              @input="onInput('lastName', 'last name', $event)"
              @blur="onBlurLastName"
              placeholder="Last Name"
              class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input
              type="text"
              :class="{
                'border-2 border-red-500' : errors.lastName,
                ...formCssClasses('input'),
              }"
              :value="lastName"
              @input="onInput('lastName', 'last name', $event)"
              @blur="onBlurLastName"
              placeholder="Doe"
              class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.lastName}}</p>
          <!-- </validation-provider> -->

        </div>

      </div>
      <!-- End name and Lastname -->

      <p
        class="block md:text-base font-normal"
        :class="{
          ...formCssClasses('label'),
        }"
      >
        This is EXACTLY as your name will appear on your Certificate
      </p>
      <!-- Number, SSN and DOB -->
      <div class="md:flex mt-6 sm:mt-10">

        <div class="md:flex-1 mb-3 md:mr-4 pt-3 md:pt-0">

          <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            Phone Number
          </label>

          <!-- <validation-provider name="number" rules="required|numeric|length:10" v-slot="{ errors }"> -->

            <!-- Mobile Version -->
            <input-facade
              mask="(###) ### - ####"
              :class="{
                'border-2 border-red-500' : errors.number,
                ...formCssClasses('input'),
              }"
              type="text"
              :value="number"
              @input.native="onInput('number', 'number', $event)"
              @blur="onBlurPhone"
              placeholder="Phone Number"
              class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- Mobile Version -->

            <!-- Desktop Version -->
            <input-facade
              mask="(###) ### - ####"
              :class="{
                'border-2 border-red-500' : errors.number,
                ...formCssClasses('input'),
              }"
              type="text"
              :value="number"
              @input.native="onInput('number', 'number', $event)"
              @blur="onBlurPhone"
              placeholder="(XXX) XXX - XXXX"
              class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.number }}</p>
          <!-- </validation-provider> -->
        </div>

        <div class="md:flex-1 mb-3 pt-3 md:pt-0">

          <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            DOB
          </label>

          <!-- <validation-provider name="DOB" rules="required|numeric|length:8" v-slot="{ errors }"> -->

            <!-- Mobile Version -->
            <input-facade
              mask="##/##/####"
              :value="dob"
              @input.native="onInput('dob', 'DOB', $event)"
              :class="{
                'border-2 border-red-500' : errors.dob,
                ...formCssClasses('input'),
              }"
              type="text"
              placeholder="DOB (MM/DD/YYYY)"
              class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input-facade
              mask="##/##/####"
              :value="dob"
              @input.native="onInput('dob', 'DOB', $event)"
              :class="{
                'border-2 border-red-500' : errors.dob,
                ...formCssClasses('input'),
              }"
              type="text"
              placeholder="MM/DD/YYYY"
              class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <!-- <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ (errors[0]) ? 'invalid format (mm/dd/yyyy)': ''}}</p> -->
            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.dob }}</p>
          <!-- </validation-provider> -->
        </div>

        <div class="md:w-3/12 mb-3 pt-3 md:pt-0" v-if="false">

          <label
            class="hidden sm:block text-md font-bold ml-2 mb-2"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            SSN  <a v-if="courseState.ssnType === 'last4'" class="text-sm text-pink-800 font-normal">(last 4)</a>
          </label>

          <!-- <validation-provider name="SSN" rules="required|numeric|length:4" v-slot="{ errors }"> -->

            <!-- Mobile Version -->
            <input-facade
              :mask="courseState.ssnType === 'full' ? '###-##-####' : '####'"
              :class="{
                'border-2 border-red-500' : errors.ssn,
                ...formCssClasses('input'),
              }"
              type="text"
              :value="ssn"
              @input.native="onInput('ssn', 'SSN', $event)"
              :placeholder="courseState.ssnType === 'full' ? 'SSN' : 'SSN  (Last 4)'"
              class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input-facade
              :mask="courseState.ssnType === 'full' ? '###-##-####' : '####'"
              :class="{
                'border-2 border-red-500' : errors.ssn,
                ...formCssClasses('input'),
              }"
              type="text"
              :value="ssn"
              @input.native="onInput('ssn', 'SSN', $event)"
              :placeholder="courseState.ssnType === 'full' ? 'XXX-XX-XXXX' : 'XXXX'"
              class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.ssn }}</p>
          <!-- </validation-provider> -->
        </div>

      </div>
      <!-- End Number, SSN and DOB -->
      <div class="flex">
      <!--  <div class="hidden md:block w-2/5 mb-3 mr-6 pt-0">
        </div>
        <div class="w-full md:w-3/5 mb-3 mr-6 pt-0">
          <label class="block text-red-900 text-sm font-normal ml-2 mb-2">
            This is required for your transcripts
          </label>
        </div>
      </div> -->

      <!-- <div class="flex flex-col md:flex-row md:mb-2">
        <div class="md:mr-6 w-full md:w-2/5">
          <label class="hidden sm:block text-red-900 text-md font-bold ml-2 mb-2">
            Gender
          </label>
          <div class="relative">
            <select
              v-model="gender"
              class="block appearance-none w-full bg-white text-red-900 py-4 px-4 pr-8 rounded leading-tight focus:outline-none shadow focus:bg-white focus:border-red-900"
            >

              <option
                v-for="(gender, i) in genders"
                :key="i"
              >
                {{ gender }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current text-red-900 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div> -->
        <!-- Graduation Doc -->
        <!-- <div v-if="graduationDocRequired" class="w-full md:w-5/5 mt-3 md:mt-0"> -->
        <div v-if="false" class="w-full md:w-5/5 mt-3 md:mt-0">
          <p
            class="block md:text-xl font-normal mb-1 md:mb-3"
            :class="{
              ...formCssClasses('label'),
            }"
          >
            Upload your High School Diploma or Equivalent
          </p>
          <div>
            <div>
              <button
                :class="{
                  ...formCssClasses('upload_button'),
                }"
                class="text-base shadow  w-full md:w-3/5 py-4"
                @click="onTriggerInputFile"
              >
                Upload
              </button>
              <input
                type="file"
                class="hidden"
                ref="graduationDocInputFile"
                @change="onGraduationDocChange"
              >
            </div>
            <div>
              <p
                class="text-red-900 font-normal md:mb-3"
                :class="{
                  ...formCssClasses('paragraph'),
                }"
              >
                {{ graduationDocName }}
              </p>
            </div>
          </div>
          <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.graduationDoc }}</p>
        </div>
        <!-- End Graduation Doc -->
      </div>
      <cpr-courses
        v-if="cprCourses.length > 0"
        :error="errors.cprCourses"
        :on-clear-error="() => { errors.cprCourses = `` }"
      />

      <div v-if="cprCourse">
        <p class="block text-red-900 md:text-2xl font-normal md:mb-3">
          Your mandatory class orientation will be on <span class="font-bold">{{ cprCourseStartDate }}</span>
        </p>
      </div>
      

      <!-- Terms of Service -->
          <div class="mt-6">
            <label class="inline-flex">
              <input
                type="checkbox"
                v-model="age"
                :class="{
                  ...formCssClasses('radio_button'),
                }"
                class="form-checkbox h-5 w-5 "
              >
                <span
                  class="ml-2"
                  :class="{
                    ...formCssClasses('label'),
                  }"
                >
                  I attest that I am at least 18 years old.
                </span>
            </label>
          </div>
          <div class="mt-2" v-if="graduationDocRequired">
            <label class="inline-flex">
              <input
                type="checkbox"
                v-model="gradDoc"
                :class="{
                  ...formCssClasses('radio_button'),
                }"
                class="form-checkbox h-5 w-5"
              >
                <span
                  class="ml-2"
                  :class="{
                    ...formCssClasses('label'),
                  }"
                >
                  I attest that I have completed Highschool, GED, or equivalent.
                </span>
            </label>
          </div>
          <div class="mt-2" >
            <label class="inline-flex items-center">
              <input type="checkbox"
                v-model="secondQuestion"
                :class="{
                  ...formCssClasses('radio_button'),
                }"
                class="form-checkbox h-5 w-5"
              >
                <span
                  class="ml-2"
                  :class="{
                    ...formCssClasses('label'),
                  }"
                >
                  I am proficient in reading and writing the English Language.
                </span>
            </label>
          </div>
          <div class="mt-2">
            <label class="inline-flex">
              <input
                type="checkbox"
                v-model="termsOfService"
                :class="{
                  ...formCssClasses('radio_button'),
                }"
                class="form-checkbox h-5 w-5"
              >
                <span
                  class="ml-2"
                  :class="{
                    ...formCssClasses('label'),
                  }"
                >
                  I have read the
                  <a class="hover:text-blue-700 text-blue-500 underline" target="_blank" :href="termsOfServiceLink">
                    enrollment agreement and privacy policy
                  </a>
                  and agree to the terms listed therein.
                </span>
            </label>
          </div>
      <!-- End Terms of Service -->

      <!-- How did you hear about us? -->
      <div>
        <!-- <hr
          class="mb-4 mt-4"
          :class="{
            ...formCssClasses('hr'),
          }"
        /> -->
        <h6
          class="text-base md:text-lg font-semibold mt-8 mb-4"
          :class="{
            ...formCssClasses('label'),
          }"
        >
          How did you hear about us?
        </h6>

        <div class="flex flex-col justify-between">
          <div class="w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0">
            <div class="relative">
              <select
                :value="referredBy"
                @change="onChange('referredBy', $event)"
                :class="{
                  'border-2 border-red-500': errors.referredBy,
                  ...formCssClasses('input'),
                }"
                class="block appearance-none w-full bg-white py-4 px-4 pr-8 rounded leading-tight focus:outline-none shadow focus:bg-white focus:border-red-900"
              >
                <option value="">Select one</option>
                <option
                  v-for="opt in referralOpts"
                  :key="opt.value"
                  :value="opt.value"
                >
                  {{ opt.text }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current text-red-900 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
              {{ errors.referredBy }}
            </p>
          </div>
          <div
            v-if="referredBy === 'other'"
            class="w-6/6 md:w-3/6 mb-4 md:mr-6 pt-3 md:pt-0"
          >
            <label
              class="text-red-900 text-md ml-2 mb-2"
              :class="{
                ...formCssClasses('label'),
              }"
            >
              Specify below
            </label>
            <input
              type="text"
              :value="commentReferredBy"
              @input="onInput('commentReferredBy','Comment Referred By', $event)"
              :class="{
                'border-2 border-red-500': errors.commentReferredBy,
                ...formCssClasses('input'),
              }"
              placeholder="Please specify"
              class="px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full mt-4"
            />
            <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
              {{ errors.commentReferredBy }}
            </p>
          </div>
        </div>
      </div>
      <!-- End How did you hear about us? -->
      <next-back-buttons :next-blocked="!ready" v-on:next="onSubmit" v-on:back="back">
      </next-back-buttons>

  <!-- </validation-observer> -->

</div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import NextBackButtons from './Elements/NextBackButtons.vue';
import CprCourses from './Elements/CprCourses.vue';

import { InputFacade } from 'vue-input-facade';
//import { ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
//import { ValidationObserver } from 'vee-validate';



export default {
  props: ['values', 'hasIntroQuestion'],
  components:{
    'next-back-buttons': NextBackButtons,
    CprCourses,
    'input-facade': InputFacade,
    //'validation-provider': ValidationProvider,
    //'validation-observer' : ValidationObserver
  },
  data: () => ({
    //genders: ['', 'female', 'male'],
    fields: ['email', 'name', 'lastName', 'number', 'dob', /* 'ssn', */ 'commentReferredBy', 'referredBy'],
    areRequired: ['email', 'name', 'lastName', 'number', 'dob', 'referredBy'/*, 'ssn'*/],
    errors: {
      email: '',
      name: '',
      lastName: '',
      number: '',
      dob: '',
      // ssn: '',
      graduationDoc: '',
      cprCourses: '',
			referredBy: '',
			commentReferredBy: '',
    },
    notices:{
      email:''
    },
    isEmailDirty: true,
    termsOfService: false,
    age: false,
    gradDoc: false,
    secondQuestion: false
  }),
  mounted(){
    //console.log('mounted', this.courseState);
    // if(this.courseState.ssnType && this.courseState.ssnType !== 'none' && this.state !== 'TN') {
    //   this.areRequired.push('ssn');
    // }
  },
  computed: {
    ...mapState('courseInformation', [
      'courseStartDate',
      'state',
      'statesGradDocNotRequired',
      'gradDocRequired',
      'cprCourse',
      'cprCourses',
      'courseState',
      'courseTypeId',
      'id',
			'referralOpts',
      'termsOfServiceLink',
    ]),
    ...mapState('transcript', ['isTranscriptReceived']),
    ...mapState('formData', ['cprCourseId']),
    ...mapState('txApplicant', ['isApproved']),
    ...mapGetters(['formCssClasses']),
    // ...mapGetters('courseInformation',['hasSecondQuestion']),
    supportPhoneNumber() {
      return process.env.VUE_APP_PHLEBS_PHONE_NUMBER;
    },
    studentPortalUrl() {
      return process.env?.VUE_APP_STUDENT_PORTAL_URL ?? 'https://students.phlebotomyusa.com';
    },
    graduationDocRequired(){
      //console.log(!this.statesGradDocNotRequired.includes( this.state ))
      // return !this.statesGradDocNotRequired.includes( this.state );
      return this.gradDocRequired;
    },
    showSSN() {
      return (this.state !== 'TN') && (this.courseState.ssnType && this.courseState.ssnType !== 'none')
    },
    cprCourseStartDate(){
      return this.cprCourse?.start_date && this.cprCourse?.hours_start
          ? this.$moment(`${this.cprCourse.start_date} ${this.cprCourse.hours_start}`).format('MMM Do YYYY h:mm a')
          : '';
    },
    ready() {
      return ( (this.secondQuestion )&& (this.gradDoc || !this.graduationDocRequired) && this.age && this.termsOfService && !this.loading)
    },
    email: {
      get() {
        return this.$store.state.formData.email;
      },
      set (value) {
        this.$store.commit('formData/updateEmail', value);
      }
    },
    name: {
      get() {
        return this.$store.state.formData.name;
      },
      set (value) {
        this.$store.commit('formData/updateName', value);
      }
    },
    lastName: {
      get() {
        return this.$store.state.formData.lastName
      },
      set (value) {
        this.$store.commit('formData/updateLastName', value)
      }
    },
    number: {
      get() {
        return this.$store.state.formData.number
      },
      set (value) {
        this.$store.commit('formData/updateNumber', value)
      }
    },
    dob: {
      get() {
        return this.$store.state.formData.dob
      },
      set (value) {
        this.$store.commit('formData/updateDob', value)
      }
    },
    ssn: {
      get() {
        return this.$store.state.formData.ssn
      },
      set (value) {
        this.$store.commit('formData/updateSsn', value)
      }
    },
    // gender: {
    //   get() {
    //     return this.$store.state.formData.gender
    //   },
    //   set (value) {
    //     this.$store.commit('formData/updateGender', value)
    //   }
    // },
    graduationDoc: {
      get() {
        return this.$store.state.formData.graduationDoc
      },
      set (value) {
        this.$store.commit('formData/updateGraduationDoc', value)
      }
    },
    graduationDocName: {
      get() {
        return this.$store.state.formData.graduationDocName
      },
      set (value) {
        this.$store.commit('formData/updateGraduationDocName', value)
      }
    },
		referredBy: {
			get() {
				return this.$store.state.formData.referredBy;
			},
			set(value) {
				this.$store.commit('formData/updateReferredBy', value);
			},
		},
		commentReferredBy: {
			get() {
				return this.$store.state.formData.commentReferredBy;
			},
			set(value) {
				this.$store.commit('formData/updateCommentReferredBy', value);
			},
		},
  },
  methods: {
		onChange(field, e) {
			const { value } = e.target;
			console.log(field, value);
			this.errors[field] = '';

			if (!value && this.areRequired.find((item) => item === field)) {
				this.errors[field] = `Field is required`;
			}

			if (value !== 'other') {
				this.commentReferredBy = '';
				this.errors.commentReferredBy = '';
			}
			this[field] = value;
		},
    onTriggerInputFile(){
      //alert();
      this.$refs.graduationDocInputFile.click();
    },
    onGraduationDocChange(e){
      const [ file ] = e.target.files;
      this.errors.graduationDoc = ``;
      if(!file){
        this.errors.graduationDoc = `The graduation document is required`;
      }
      this.graduationDoc = file || null;
      this.graduationDocName = file ? file.name : ``;
      //console.log(file);
    },
    onInput(field, fieldName, e){
      const { value } = e.target
      //console.log(value.length)
      this.errors[field] = '';

      if(field === 'email') {
        this.notices.email = '';
        this.isEmailDirty= true;
      }
      if(!value && this.areRequired.find( item => item === field )){
        this.errors[field] = `The ${fieldName} field is required`
      }
      // valid email validation
      if(field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        this.errors[field] = `The ${fieldName} must be a valid.`
      }
      // phone number validation
      if(field === 'number' && value.length < 16) {
        this.errors[field] = `The ${fieldName} field must be 10 long`
      }
      // dob format and age 18+ validation
      if(field === 'dob') {

        //const validDate = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
        const validDate =/^\d{2}\/\d{2}\/\d{4}$/
        if(!validDate.test(value)) {
          this.errors[field] = `The ${fieldName} must be a valid date.`
        }

        const dob = value.trim().split('/');

        if(dob[0] && dob[1] && dob[2] && validDate.test(value)) {
          const month = parseInt(dob[0].trim()) - 1;
          const day = parseInt(dob[1].trim());
          const year = parseInt(dob[2].trim());
          //const yearsOld = 18;
          const birthDate = this.$moment([year,month,day]).add(18, 'years').format('YYYY-MM-DD');
          const courseDate = this.$moment(this.courseStartDate).format('YYYY-MM-DD');
          this.errors[field] = !this.$moment(birthDate).isSameOrBefore(courseDate) ?
            'You must be 18 years old by the first day of classes.' :
            '';
        }
      }
      // ssn validation
      if(field === 'ssn' && this.courseState.ssnType && this.courseState.ssnType !== 'none') {

        if(this.courseState.ssnType === 'full' && value.length < 11) {
          this.errors[field] = `The ${fieldName} must be a valid format.`
        } else if(this.courseState.ssnType === 'last4' && value.length < 4) {
          //console.log(value.length);
          this.errors[field] = `The ${fieldName} must be a valid format.`
        }
        //console.log(value.length);
      }
			if (
				!value &&
				field === 'commentReferredBy' &&
				this.referredBy === 'other'
			) {
				this.errors[field] = `Field is required`;
			}

      this[field] = value
      //console.log(field, target.value);
    },
    onSubmit() {
      //try {
        console.log(this.errors);
        for (const field of this.fields) {
          if(this.errors[field]){
            return;
          }

          if(!this[field] && this.areRequired.find( item => item === field )){
            this.errors[field] = 
						field === 'referredBy'
							? 'Field is required'
							: `The ${field} field is required`;
            return;
          }
          if (
            field === 'commentReferredBy' &&
            !this[field] &&
            this.referredBy === 'other'
          ) {
            this.errors[field] = `Field is required`;
            return;
          }
        }



      if (this.ready){
        this.$emit('next');
      }


        //const errors = this.$refs.formBasic.errors;

          // if(
          //   errors.email.length === 0 &&
          //   errors.DOB.length === 0 &&
          //   (!this.graduationDocRequired || (this.graduationDocRequired && this.graduationDoc))
          // ){
          // if(
          //     errors.email.length === 0 && errors.DOB.length === 0 &&
          //     ( (this.cprCourses.length > 0 && !!this.cprCourseId) || (this.cprCourses.length === 0) )
          //   ){
          //   // if(!this.graduationDoc){
          //   //   this.errors.graduationDoc = `The graduation document is required`;
          //   //   return;
          //   // }
          //   this.$emit('next');
          // }
          // else{
            //console.log(errors);
            // if(errors.email.length > 0){
            //   await this.$refs.formBasic.setErrors({
            //     email: [`It looks like you already have an account with us. Please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} to register to a new class.`]
            //   });
            // } else if(errors.DOB.length > 0){
            //   await this.$refs.formBasic.setErrors({
            //     DOB: [
            //       `You must be 18 years old by the first day of classes.`
            //     ]
            //   });
            // } else if(this.cprCourses.length > 0 && !this.cprCourseId){
            //   this.errors.cprCourses = `CPR course is required`
            // }

            // if(!this.graduationDoc && this.graduationDocRequired){
            //   this.errors.graduationDoc = `The graduation document is required`;
            // }

          // }


      // } catch (error) {
      //   console.log(error);
      // }

    },
    back(){
      this.$emit('back');
    },
    async onBlurDoB(e){
      //this.courseStartDate;
      const validDate = /^\d{2}[./-]\d{2}[./-]\d{4}$/;

      if(!validDate.test(e.target.value)){
        await this.$refs.formBasic.setErrors({
          DOB: [`Please add a valid date.`]
        })
        return;
      }

      const dob = e.target.value.trim().split('/');

      const month = parseInt(dob[0].trim()) - 1;
      const day = parseInt(dob[1].trim());
      const year = parseInt(dob[2].trim());
      //const yearsOld = 18;
      const birthDate = this.$moment([year,month,day]).add(18, 'years').format('YYYY-MM-DD');
      const courseDate = this.$moment(this.courseStartDate).format('YYYY-MM-DD');
      //console.log(birthDate, courseDate);
      //console.log(this.$moment(birthDate).isSameOrBefore(courseDate));
      if(!this.$moment(birthDate).isSameOrBefore(courseDate)){
        await this.$refs.formBasic.setErrors({
          DOB: [`You must be 18 years old by the first day of classes.`]
        });
        return;
      }

      // if(!this.$moment(birthDate).isBefore(courseDate)){
      //   await this.$refs.formBasic.setErrors({
      //     DOB: [`You must be 18 years old by the first day of classes.`]
      //   });
      //   return;
      // }
    },
    async onBlurEmail(e){

      //console.log('blur');
      //console.log(this.$refs.formBasic.validate());
      const email = e.target.value.trim();
      //const validEmail = /\S+@\S+\.\S+/.test(email);
      //const url =
      //console.log(validEmail);
      if(this.errors.email){
        return;
      }
      if (this.isEmailDirty === false) {
       return ;
      }
      if(email === ""){
        return;
      }

      //if(validEmail){
        try {
          this.isEmailDirty=false
          //const { data } = await axios.get(process.env.VUE_APP_API_URL+'api/student/'+ email +'/verify');
          const query = new URLSearchParams();
          query.set('email', email);
          query.set('course_id', this.id);
          const { data } = await axios.get(`${process.env.VUE_APP_API_URL}api/student/verify_email?${query.toString()}`)

          if (data.result?.suggestion) {
            this.notices.email = 'suggestion';
            this.suggestion = data.result.suggestion
            return;
          }
          if (data.result &&  data.result.verdict === 'Invalid') {
            this.notices.email = 'Invalid'; 
            return;
          }else if(data.result &&  data.result.verdict === 'Risky' && data.result.score < 0.5 ){
            this.notices.email = 'Risky'; 
            return;
          }else{
            this.notices.email= ""
          }

          if(!data.exists) return;

          if(!data.can_register) {
            this.errors.email = 'exists';
            return;
          }

          this.errors.email = 'can_register';

        } catch (error) {
          this.isEmailDirty=false
          console.log(error);
        }
      //}

    },
    async onBlurFirstName(e){
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}api/student/update_lead`, {
          email : this.email,
          type: "first_name",
          value: this.name 
        })
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async onBlurLastName(e){
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}api/student/update_lead`, {
          email : this.email,
          type: "last_name",
          value: this.lastName 
        })
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async onBlurPhone(e){
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}api/student/update_lead`, {
          email : this.email,
          type: "phone",
          value: this.number 
        })
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style>
  /*select option {
    color: #7F1D1D;
  }

  select option:checked {
    color: #7F1D1D;
  }

  option[hidden]:checked {
    color: yellow;
  }*/


</style>
